import React from 'react';
import {Link} from 'gatsby'

import VectorShape11 from '../WeServe/vector-shape11.png'

const Seqtorebi = () => {
    return (
        <section className="about-area ptb-100">

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}

        <div className="container">
            <div className="about-inner-area">
            <h3>სექტორები</h3>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="about-text">
                            
                            {/* <p>Real innovations.</p> */}
                            
                            <ul className="features-list">
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ავტო" className="learn-more-btn">
                                        ავტო
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ავტოსერვისი" className="learn-more-btn">
                                        ავტოსერვისი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ავტონაწილები" className="learn-more-btn">
                                        ავტონაწილები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ავეჯი" className="learn-more-btn">
                                        ავეჯი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბენზინგასამართი" className="learn-more-btn">
                                        ბენზინგასამართი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ტექნიკა" className="learn-more-btn">
                                        ტექნიკა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გაქირავება" className="learn-more-btn">
                                        გაქირავება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გაზგასამართი" className="learn-more-btn">
                                        გაზგასამართი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/დასუფთავება" className="learn-more-btn">
                                        დასუფთავება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/დიზაინი" className="learn-more-btn">
                                        დიზაინი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სასტუმროები" className="learn-more-btn">
                                        სასტუმროები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/მასალები" className="learn-more-btn">
                                        მასალები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/მაღაზიები" className="learn-more-btn">
                                        მაღაზიები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/აფთიაქები" className="learn-more-btn">
                                        აფთიაქები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სასწავლებლები" className="learn-more-btn">
                                        სასწავლებლები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ანსამბლები" className="learn-more-btn">
                                        ანსამბლები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/მაღაზიები" className="learn-more-btn">
                                        მაღაზიები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/დასვენება" className="learn-more-btn">
                                        დასვენება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბავშთა" className="learn-more-btn">
                                        ბავშთა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ფიტნესი" className="learn-more-btn">
                                        ფიტნესი
                                    </Link>
                                </li>
                                {/* <li><i className="flaticon-right"> </i>
                                    <Link to="/აბანოები" className="learn-more-btn">
                                        აბანოები
                                    </Link>
                                </li> */}
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/უძრავი-ქონება" className="learn-more-btn">
                                        უძრავი-ქონება
                                    </Link>
                                </li>

                            </ul>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="about-text">
                            {/* <h3>Our Mission</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ფინანსები" className="learn-more-btn">
                                        ფინანსები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/კვლევა" className="learn-more-btn">
                                        კვლევა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ვაჭრობა" className="learn-more-btn">
                                        ვაჭრობა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/მომსახურება" className="learn-more-btn">
                                        მომსახურება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბეჭდვა" className="learn-more-btn">
                                        ბეჭდვა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გაქირავება" className="learn-more-btn">
                                        გაქირავება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სამშენებლო" className="learn-more-btn">
                                        სამშენებლო
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ორგანიზაციები" className="learn-more-btn">
                                        ორგანიზაციები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/რკინიგზა" className="learn-more-btn">
                                        რკინიგზა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/პროექტირება" className="learn-more-btn">
                                        პროექტირება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გათბობა" className="learn-more-btn">
                                        გათბობა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სამედიცინო" className="learn-more-btn">
                                        სამედიცინო
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ტრეინინგი" className="learn-more-btn">
                                        ტრეინინგი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/საკონსულტაციო" className="learn-more-btn">
                                        საკონსულტაციო
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ინტერნეტი" className="learn-more-btn">
                                        ინტერნეტი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბანკები" className="learn-more-btn">
                                        ბანკები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/კომპიუტერული" className="learn-more-btn">
                                        კომპიუტერული
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სასამართლოები" className="learn-more-btn">
                                        სასამართლოები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/უსაფრთხოება" className="learn-more-btn">
                                        უსაფრთხოება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სანტექნიკა" className="learn-more-btn">
                                        სანტექნიკა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სტუდიები" className="learn-more-btn">
                                        სტუდიები
                                    </Link>
                                </li>


                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="about-text">
                            {/* <h3>Who we are</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/დარბაზები" className="learn-more-btn">
                                        დარბაზები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბიზნესი" className="learn-more-btn">
                                        ბიზნესი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/პარკები" className="learn-more-btn">
                                        პარკები
                                    </Link>
                                </li>
                                {/* <li><i className="flaticon-right"> </i>
                                    <Link to="/კონსულტაციები" className="learn-more-btn">
                                        კონსულტაციები
                                    </Link>
                                </li> */}
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბიბლიოთეკები" className="learn-more-btn">
                                        ბიბლიოთეკები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/გართობა" className="learn-more-btn">
                                        გართობა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ადვოკატები" className="learn-more-btn">
                                        ადვოკატები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/კურსები" className="learn-more-btn">
                                        კურსები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ავია" className="learn-more-btn">
                                        ავია
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ტაქსი" className="learn-more-btn">
                                        ტაქსი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ავტოსადგურები" className="learn-more-btn">
                                        ავტოსადგურები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ტურები" className="learn-more-btn">
                                        ტურები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ტანსაცმელი" className="learn-more-btn">
                                        ტანსაცმელი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სპორტი" className="learn-more-btn">
                                        სპორტი
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/რეკლამა" className="learn-more-btn">
                                        რეკლამა
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/განათება" className="learn-more-btn">
                                        განათება
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/სალონები" className="learn-more-btn">
                                        სალონები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/თავშესაფრები" className="learn-more-btn">
                                        თავშესაფრები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/კლინიკები" className="learn-more-btn">
                                        კლინიკები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/მეურნეობები" className="learn-more-btn">
                                        მეურნეობები
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/ბაზრები" className="learn-more-btn">
                                        ბაზრები
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}
    </section>
    )
}

export default Seqtorebi