import React from 'react';
import {Layout} from "@layout/Layout/Layout";
import {Link} from 'gatsby'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

import Seqtorebi from '@elegantstack/gatsby-starter-flexiblog-agency/src/components/Seqtorebi'

const Navigator = (props) => {
    return (
        <Layout {...props}>
            {/* <Seo title='Pinpoint.ge' /> */}
            <Seo title='ბიზნეს ინფო სექტორების მიხედვით' description='იპოვეთ ბიზნეს მისამართები სექტორების მიხედვით' />
            {/* <Divider /> */}
            <Seqtorebi/>
        </Layout>
    );
};

export default Navigator;